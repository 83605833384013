<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            label="기준년도"
            name="srchYear"
            type="year"
            v-model="searchParam.srchYear"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="grid"
      title="원료사용량"
      tableId="grid"
      :columns="grid.columns"
      :data="grid.data"
      :usePaging="true"
      :columnSetting="false"
      :filtering="true"
      :isExcelDown="false"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable && grid.data.length > 0" label="SEMS 다운로드" icon="download" @btnClicked="downLoadSems"/>
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'air-sems-01',
  data() {
    return {
      editable: true,
      searchParam: {
        plantCd: null,
        srchYear: '',
        srchDts: [],
      },
      grid: {
        columns: [
          {
            name: 'envAirMstMaterialName',
            field: 'envAirMstMaterialName',
            label: '사용원료명',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
            fix: true,
          },
          {
            name: 'airMaterialUnitName',
            field: 'airMaterialUnitName',
            label: '사용량단위',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
            fix: true,
          },
          {
            name: 'mon01',
            field: 'mon01',
            label: '사용량1월',
            type: 'cost',
            style: 'width: 80px',
            sortable: false,
          },
          {
            name: 'mon02',
            field: 'mon02',
            label: '사용량2월',
            type: 'cost',
            style: 'width: 80px',
            sortable: false,
          },
          {
            name: 'mon03',
            field: 'mon03',
            label: '사용량3월',
            type: 'cost',
            style: 'width: 80px',
            sortable: false,
          },
          {
            name: 'mon04',
            field: 'mon04',
            label: '사용량4월',
            type: 'cost',
            style: 'width: 80px',
            sortable: false,
          },
          {
            name: 'mon05',
            field: 'mon05',
            label: '사용량5월',
            type: 'cost',
            style: 'width: 80px',
            sortable: false,
          },
          {
            name: 'mon06',
            field: 'mon06',
            label: '사용량6월',
            type: 'cost',
            style: 'width: 80px',
            sortable: false,
          },
          {
            name: 'mon07',
            field: 'mon07',
            label: '사용량7월',
            type: 'cost',
            style: 'width: 80px',
            sortable: false,
          },
          {
            name: 'mon08',
            field: 'mon08',
            label: '사용량8월',
            type: 'cost',
            style: 'width: 80px',
            sortable: false,
          },
          {
            name: 'mon09',
            field: 'mon09',
            label: '사용량9월',
            type: 'cost',
            style: 'width: 80px',
            sortable: false,
          },
          {
            name: 'mon10',
            field: 'mon10',
            label: '사용량10월',
            type: 'cost',
            style: 'width: 80px',
            sortable: false,
          },
          {
            name: 'mon11',
            field: 'mon11',
            label: '사용량11월',
            type: 'cost',
            style: 'width: 80px',
            sortable: false,
          },
          {
            name: 'mon12',
            field: 'mon12',
            label: '사용량12월',
            type: 'cost',
            style: 'width: 80px',
            sortable: false,
          },
        ],
        data: [],
      },
      listUrl: '',
      saveUrl: '',
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  computed: {
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.listUrl = selectConfig.env.air.sems.materials.list.url;
      this.editable = this.$route.meta.editable;
      this.searchParam.srchYear = this.$comm.getThisYear();
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param =this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    downLoadSems() {
      let thisVue = this;
      this.$http.url = selectConfig.env.air.sems.materials.list.url + '/excel';
      this.$http.param =this.searchParam;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
          var blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, "SEMS원료사용량엑셀양식.xlsx");
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "SEMS원료사용량엑셀양식.xlsx";
            link.click();
          }
      },);
    },
  }
};
</script>
